import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";
import Select from "react-select";

export default function ReportKeyword() {
    const [loading, setLoading] = useState(false);
    const { staff } = useSelector((state) => state.auth);
    const [properties, setProperties] = useState([]);

    const { current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);

    const [dateRangeReport, setDateRangeReport] = useState([null, null]);
    const [startDateReport, endDateReport] = dateRangeReport;

    const keywords = [
        { value: 1, label: "Fumigation" },
        { value: 2, label: "Xmas in July" },
        { value: 3, label: "Shesha3.2.1" },
        { value: 4, label: "SIGN UP" },
        { value: 5, label: "HIKE RSVP" },
    ];

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Export All</h1>

                <Formik
                    initialValues={{
                        property_id: 0,
                        startDateReport: "",
                        endDateReport: "",
                        keyword: "",
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}reports/export_keyword`,
                            responseType: "blob",
                            data: { start_date: startDateReport, end_date: endDateReport, keyword: values.keyword },
                        })
                            .then((response) => {
                                const href = URL.createObjectURL(response.data);

                                const link = document.createElement("a");
                                link.href = href;
                                link.setAttribute("download", "KeywordReport.xlsx");
                                document.body.appendChild(link);
                                link.click();

                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                <div className="grid lg:grid-cols-12 md:lg:grid-cols-6 gap-12 mt-5 mb-5 ">
                                    {/* <SettingsSelect label="What is your desired Building?" name="property_id" onChange={setFieldValue} value={values.property_id} options={properties} /> */}

                                    <div className="col-span-3">
                                        <label className="text-sm font-medium mt-5 text-gray-700">
                                            <span>Choose a date range</span>
                                        </label>
                                        <DatePicker
                                            selectsRange={true}
                                            startDate={startDateReport}
                                            endDate={endDateReport}
                                            onChange={(update) => {
                                                setDateRangeReport(update);
                                            }}
                                            label={"Date Range"}
                                            dateFormat={"d MMMM yyyy"}
                                            isClearable={true}
                                            showYearDropdown
                                            showMonthDropdown
                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                        />
                                    </div>

                                    <div className="col-span-3">
                                        <label className="text-sm font-medium mt-5 text-gray-700">
                                            <span>Choose a Keyword</span>
                                        </label>
                                        <Select
                                            options={keywords}
                                            onChange={(v) => setFieldValue("keyword", v.label)}
                                            className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                        />
                                    </div>
                                </div>

                                <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} text="Download Report" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
