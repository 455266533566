import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";

// import { initializeApp } from "firebase/app";
// import { getFirestore, setDoc, onSnapshot, collection, query, where, orderBy, limit } from "firebase/firestore";

import Echo from "laravel-echo";

import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Disclosure, Transition, Popover } from "@headlessui/react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { openModal } from "../../store/modal/modalReducer";
import ErrorBoundary from "../../containers/ErrorBoundary";

import NotificationToast from "../../components/Helpers/NotificationToast";
import { capitalize } from "../../components/Helpers/Helper";
import { getBooking } from "../../store/booking/bookingActions";
import AppLoader from "../../components/Helpers/AppLoader";
import BookingForm from "../../components/Booking/BookingForm";
import GridSaveInformation from "../../components/Booking/GridSaveInformation";
import BookingList from "../../components/Booking/BookingList";
import BookingMessages from "../../components/Booking/BookingMessages";
import LoadingIcon from "../../components/Helpers/LoadingIcon";
import { getSender } from "../../components/Helpers/Helper";
import CustomButton from "../../components/Helpers/CustomButton";
import BookingButtons from "../../components/Booking/BookingButtons";
import GridInvoiceInformation from "../../components/Booking/GridInvoiceInformation";
import ProfilePicture from "../../components/Auth/ProfilePicture";
import GridBookingInformation from "../../components/Booking/GridBookingInformation";
import GridPayments from "../../components/Booking/GridPayments";
import GridAdditionalInvoice from "../../components/Booking/GridAdditionalInvoice";
import GridMoveIn from "../../components/Booking/GridMoveIn";
import AtInfo from "../../components/Booking/AtInfo";
import GridSettings from "../../components/Booking/GridSettings";
import GridBookingAppointments from "../../components/Booking/GridBookingAppointments";
import CustomOrders from "../../components/Booking/CustomOrders";
import GridBiometrics from "../../components/Booking/GridBiometrics";
import QueuePlate from "../../components/Booking/QueuePlate";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function BookingGrid(props) {
    const dispatch = useDispatch();

    const { type } = useParams();

    const { current_staff, staff } = useSelector((state) => state.auth);
    const { current_booking, flagged_ids } = useSelector((state) => state.booking);
    const { tags } = useSelector((state) => state.tags);
    const { folders } = useSelector((state) => state.folders);
    const { settings } = useSelector((state) => state.settings);

    const [messages, setMessages] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [filterCount, setFilterCount] = useState(0);
    const [allBookings, setAllBookings] = useState([]);
    const [historicBookings, setHistoricBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [messageLoading, setMessageLoading] = useState(false);
    const [mdaLoadingT, setMdaLoadingT] = useState(false);
    const [mdaLoadingL, setMdaLoadingL] = useState(false);
    const [tpnLoading, setTpnLoading] = useState(false);
    // const [assignedFilter, setAssignedFilter] = useState("mine");
    const [sortFilter, setSortFilter] = useState("asc");
    const [filterLocation, setFilterLocation] = useState({ id: 0, name: "" });
    const [filterGender, setFilterGender] = useState({ id: 0, name: "" });
    const [filterFunding, setFilterFunding] = useState({ id: 0, name: "" });
    const [filterLocationChange, setFilterLocationChange] = useState({ id: 0, name: "" });
    const [filterDocuments, setFilterDocuments] = useState({ id: 0, name: "" });

    const [filterVendor, setFilterVendor] = useState({ id: 0, name: "" });
    const [filterStatus, setFilterStatus] = useState({ id: 0, name: "" });

    const { properties } = useSelector((state) => state.properties);
    const { collections } = useSelector((state) => state.collections);
    const { renewals } = useSelector((state) => state.renewals);

    const [allowClick, setAllowClick] = useState(true);
    const [firstSearch, setFirstSearch] = useState(true);

    const [showManualLink, setShowManualLink] = useState(false);
    const [mdaBalance, setMdaBalance] = useState(null);

    const [doSub, setDoSub] = useState("");
    const [doSubTag, setDoSubTag] = useState("");
    const [doSubMention, setDoSubMention] = useState("");
    const [sortDir, setSortDir] = useState("asc");
    const [showNewMessages, setShowNewMessages] = useState(false);
    const [oldBooking, setOldBooking] = useState(0);

    const [chatsLoading, setChatsLoading] = useState(false);
    const [chatsMsgLoading, setChatsMsgLoading] = useState(false);

    const [ignoreRefresh, setIgnoreRefresh] = useState(false);

    const [checkingUpdates, setCheckingUpdates] = useState(false);
    const [activeTPN, setActiveTPN] = useState([]);

    const [wiFiLoading, setWiFiLoading] = useState(false);
    const [wiFiLoading2, setWiFiLoading2] = useState(false);
    const [wifiUsername, setWifiUsername] = useState("");
    const [wifiPassword, setWifiPassword] = useState("");

    const location = useLocation();

    const [msgCount, setMsgCount] = useState(25);

    const genderOptions = [
        { value: 1, label: "Male" },
        { value: 2, label: "Female" },
    ];

    const fundingOptions = [
        { label: "NSFAS UJ", value: 4 },
        { label: "NSFAS WITS", value: 5 },
        { label: "NSFAS CJC", value: 6 },
        { label: "Bursary", value: 2 },
        { label: "Private", value: 3 },
    ];

    const handleHistoricBookingsCallback = (childData) => {
        setHistoricBookings(childData);
    };

    var url_string = window.location.href;

    var url = new URL(url_string);
    var tag_id = url.searchParams.get("tag");
    var collection_tag_id = url.searchParams.get("collection_tag");
    var renewal_tag = url.searchParams.get("renewal_tag");
    var user_id = url.searchParams.get("user_id");
    var active_booking_id = url.searchParams.get("booking_id");
    var pathnameLocation = url.pathname.split("/").pop();

    useEffect(async () => {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;

        let echo;

        if (process.env.REACT_APP_REVERB_APP_ID) {
            echo = new Echo({
                broadcaster: "reverb", // Change "pusher" to "reverb"
                key: process.env.REACT_APP_REVERB_APP_KEY,
                wsHost: process.env.REACT_APP_REVERB_URL,
                wssHost: process.env.REACT_APP_REVERB_URL,
                wsPort: process.env.REACT_APP_REVERB_PORT,
                wssPort: process.env.REACT_APP_REVERB_PORT,
                disableStats: true,
                encrypted: process.env.REACT_APP_REVERB_SCHEME === "https",
                forceTLS: process.env.REACT_APP_REVERB_SCHEME === "https",
                enableLogging: true,
            });
        } else {
            echo = new Echo({
                broadcaster: "pusher",
                key: process.env.REACT_APP_MIX_ABLY_PUBLIC_KEY,

                wsHost: process.env.REACT_APP_MIX_ABLY_URL,
                wssHost: process.env.REACT_APP_MIX_ABLY_URL,

                wsPort: process.env.REACT_APP_MIX_ABLY_PORT,
                wssPort: process.env.REACT_APP_MIX_ABLY_PORT,

                disableStats: true,
                encrypted: false,
                enabledTransports: ["ws", "wss"],
                forceTLS: false,

                enableLogging: true,

                // unavailableTimeout: 1000,
                // activityTimeout: 1000,
                // pongTimeout: 2000,
            });
        }

        if (
            doSub === "" ||
            doSub !== type ||
            ((doSub == "management" || doSub == "historical" || doSub == "collections" || doSub == "brokers" || doSub == "renewals") && doSubTag === "") ||
            ((doSub == "management" || doSub == "historical" || doSub == "collections" || doSub == "brokers" || doSub == "renewals") && doSubTag !== tag_id) ||
            (props.history.location.state !== undefined && props.history.location.state?.from !== undefined) ||
            messageLoading === true
        ) {
            if (doSub !== "") {
                // echo.channel(`Messages.${doSub}`).stopListening('GetBookings');
                // echo.leave(`Messages.${doSub}`);
                // console.log('Leave ' + doSub);
                // echo.channel(`Messages.returningstudents`).stopListening('GetBookings')
                // echo.leave(`Messages.returningstudents`)
                // echo.channel(`Messages.returningstudents`).unsubscribe()
                // echo.channel(`Messages.returningstudents`).stopListening('GetBookings');
                // echo.leaveChannel(`Messages.returningstudents`);
                // echo.leave(`Messages.returningstudents`);
            }

            console.log("type " + type);

            if (type !== "management" && type !== "historical" && type !== "mentions" && type !== "collections" && type !== "brokers" && type !== "renewals") {
                echo.channel(`Messages.${type}`)
                    .subscribed(() => {
                        console.log("You are subscribed Message List " + type);
                        if (messageLoading === false) {
                            setChatsLoading(true);
                        }
                        // console.log("TYPE", type)
                        setLoading(false);
                        setDoSub(type);

                        //So this event will trigger all subscribed users to re-get the messages just because you subscribed.
                        //I think we should change this to get the messages from laravel rather than firing the event and then getting them from the event
                        /*
                        So use this flow:
                        1. I click on a folder
                        2. I subscribe to the folder
                        3. I get a list of the messages from server (not event)
                        4. When something changes, say a new message comes in I get those changes via my subscribed event
                        */

                        if (type != "archived") {
                            axios({
                                method: "get",
                                url: `${process.env.REACT_APP_API_URL}event/${type}`,
                            });
                        } else {
                            axios({
                                method: "get",
                                url: `${process.env.REACT_APP_API_URL}get_archived`,
                            }).then((response) => {
                                const array = [];

                                Object.keys(response.data).forEach((key) => {
                                    array.push(response.data[key]);
                                });

                                if (sortFilter === "asc") {
                                    array.sort((a, b) => (a.n > b.n ? -1 : 1));
                                } else {
                                    array.sort((a, b) => (a.n > b.n ? 1 : -1));
                                }

                                setBookings(array);
                                setChatsLoading(false);
                            });
                        }

                        // console.log(filterLocation.id);
                    })
                    .listen("GetBookings", (data) => {
                        // console.log("123");
                        // console.log(data);

                        if (data[1] === window.location.href.split("/").pop().split("?")[0]) {
                            const array = [];

                            Object.keys(data[0]).forEach((key) => {
                                // if (filterLocation.id === 0 || filterLocation.id == data[0][key].property_id) {

                                array.push(data[0][key]);

                                // }
                            });

                            if (sortFilter === "asc") {
                                array.sort((a, b) => (a.n > b.n ? -1 : 1));
                                // array.sort((a, b) => (a.last_message_sent > b.last_message_sent ? -1 : 1));
                            } else {
                                array.sort((a, b) => (a.n > b.n ? 1 : -1));
                                // array.sort((a, b) => (a.last_message_sent > b.last_message_sent ? 1 : -1));
                            }

                            setBookings(array);
                            setChatsLoading(false);
                            // console.log("listen | 1 ");

                            // console.log("doSub listen | 1 | ", doSub, type, window.location.href.split("/").pop());
                            // console.log("Booking Count:", array.length)
                        }
                    });
            } else if (type == "management" || type == "historical") {
                echo.channel(`TagMessages.${tag_id}`)
                    .subscribed(() => {
                        console.log("You are subscribed Message List Tags");
                        if (messageLoading === false) {
                            setChatsLoading(true);
                        }
                        setLoading(false);
                        setDoSub(type);
                        setDoSubTag(tag_id);
                        // tag_id

                        axios({
                            method: "get",
                            url: `${process.env.REACT_APP_API_URL}tag_event/${tag_id}/${type}`,
                        });

                        // console.log(window.location.href.split("/").pop().split("=")[1].split('&')[0])
                    })
                    .listen("TagBookingsEvent", (data) => {
                        if (data[1] === window.location.href.split("/").pop().split("=")[1].split("&")[0]) {
                            const array = [];
                            Object.keys(data[0]).forEach((key) => {
                                // if (filterLocation.id === 0 || filterLocation.id == data[0][key].property_id) {

                                array.push(data[0][key]);
                                // }
                            });

                            if (sortFilter === "asc") {
                                array.sort((a, b) => (a.n > b.n ? -1 : 1));
                                // array.sort((a, b) => (a.last_message_sent > b.last_message_sent ? -1 : 1));
                            } else {
                                array.sort((a, b) => (a.n > b.n ? 1 : -1));
                                // array.sort((a, b) => (a.last_message_sent > b.last_message_sent ? 1 : -1));
                            }

                            setBookings(array);
                            setChatsLoading(false);
                            // console.log("doSub listen | 2 |", doSubTag, tag_id, data[1], window.location.href.split("/").pop().split("=").pop());

                            // console.log("listen | 2 ");
                        }
                    });
            } else if (type == "collections") {
                echo.channel(`CollectionMessages.${collection_tag_id}`)
                    .subscribed(() => {
                        console.log("You are subscribed Message List Collections");
                        if (messageLoading === false) {
                            setChatsLoading(true);
                        }
                        setLoading(false);
                        setDoSub(type);
                        setDoSubTag(collection_tag_id);

                        axios({
                            method: "get",
                            url: `${process.env.REACT_APP_API_URL}collection_event/${collection_tag_id}`,
                        });
                    })
                    .listen("CollectionBookingEvent", (data) => {
                        if (data[1] === window.location.href.split("/").pop().split("=")[1].split("&")[0]) {
                            const array = [];
                            Object.keys(data[0]).forEach((key) => {
                                array.push(data[0][key]);
                            });

                            if (sortFilter === "asc") {
                                array.sort((a, b) => (a.n > b.n ? -1 : 1));
                            } else {
                                array.sort((a, b) => (a.n > b.n ? 1 : -1));
                            }

                            setBookings(array);
                            setChatsLoading(false);
                        }
                    });
            } else if (type == "brokers") {
                echo.channel(`BrokerBookings.1`)
                    .subscribed(() => {
                        console.log("You are subscribed Message List Brokers 2");
                        if (messageLoading === false) {
                            setChatsLoading(true);
                        }
                        setLoading(false);
                        setDoSub(type);
                        setDoSubTag(1);

                        axios({
                            method: "get",
                            url: `${process.env.REACT_APP_API_URL}brokersBookings_event/1`,
                        });
                    })
                    .listen("BrokersBookings", (data) => {
                        const array = [];
                        Object.keys(data[0]).forEach((key) => {
                            array.push(data[0][key]);
                        });

                        if (sortFilter === "asc") {
                            array.sort((a, b) => (a.n > b.n ? -1 : 1));
                        } else {
                            array.sort((a, b) => (a.n > b.n ? 1 : -1));
                        }

                        setBookings(array);
                        setChatsLoading(false);
                    });
            } else if (type == "renewals") {
                echo.channel(`RenewalBookingsData.${renewal_tag}`)
                    .subscribed(() => {
                        console.log("You are subscribed Message List Renewals 2", renewal_tag);
                        if (messageLoading === false) {
                            setChatsLoading(true);
                        }
                        setLoading(false);
                        setDoSub(type);
                        setDoSubTag(renewal_tag);

                        axios({
                            method: "get",
                            url: `${process.env.REACT_APP_API_URL}renewalsBookings_event/${renewal_tag}`,
                        });
                    })
                    .listen("RenewalsBookings", (data) => {
                        if (data[1] === window.location.href.split("/").pop().split("=")[1].split("&")[0]) {
                            const array = [];
                            Object.keys(data[0]).forEach((key) => {
                                array.push(data[0][key]);
                            });

                            if (sortFilter === "asc") {
                                array.sort((a, b) => (a.n > b.n ? -1 : 1));
                            } else {
                                array.sort((a, b) => (a.n > b.n ? 1 : -1));
                            }

                            setBookings(array);
                            setChatsLoading(false);
                        }
                    });
            } else {
                echo.channel(`MentionBooking.${current_staff.id}`)
                    .subscribed(() => {
                        console.log("You are subscribed Message List Mentions");
                        if (messageLoading === false) {
                            setChatsLoading(true);
                        }
                        setLoading(false);
                        setDoSub(type);
                        setDoSubMention(current_staff.id);

                        axios({
                            method: "get",
                            url: `${process.env.REACT_APP_API_URL}mentions/${current_staff.id}`,
                        });
                    })
                    .listen("MentionBookings", (data) => {
                        const array = [];
                        Object.keys(data).forEach((key) => {
                            // if (filterLocation.id === 0 || filterLocation.id == data[key].property_id) {
                            let mentionArr = data[key].q !== null && data[key].q.includes(",") ? data[key].q.split(",") : data[key].q;
                            if (mentionArr.includes(current_staff.id.toString())) {
                                array.push(data[key]);
                            }
                            // }
                        });

                        if (sortFilter === "asc") {
                            array.sort((a, b) => (a.n > b.n ? -1 : 1));
                            // array.sort((a, b) => (a.last_message_sent > b.last_message_sent ? -1 : 1));
                        } else {
                            array.sort((a, b) => (a.n > b.n ? 1 : -1));
                            // array.sort((a, b) => (a.last_message_sent > b.last_message_sent ? 1 : -1));
                        }

                        // console.log(array)

                        setBookings(array);
                        setChatsLoading(false);
                        // console.log("doSub listen | 3 |", doSub, type, data);
                        // console.log("listen | 3 ");
                    });
            }

            // console.log();
        }

        if (sortFilter !== sortDir) {
            setSortDir(sortFilter);

            let allBookings = bookings;

            if (sortFilter === "asc") {
                allBookings.sort((a, b) => (a.n > b.n ? -1 : 1));
                // allBookings.sort((a, b) => (a.last_message_sent > b.last_message_sent ? -1 : 1));
            } else {
                allBookings.sort((a, b) => (a.n > b.n ? 1 : -1));
                // allBookings.sort((a, b) => (a.last_message_sent > b.last_message_sent ? 1 : -1));
            }

            setBookings(allBookings);
        }

        //&& ignoreRefresh === false

        if ((current_booking !== undefined && messageLoading === true) || ignoreRefresh === true) {
            //Axio call to get all messages

            await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}get_booking_messages`,
                data: { booking_id: current_booking.id },
            }).then((response) => {
                let data = response.data;
                const array = [];
                Object.keys(data).forEach((key) => {
                    if (data[key].files !== null) {
                        data[key].files = data[key].files.split(",");
                    } else {
                        data[key].files = [];
                    }

                    data[key].timestamp = format(new Date(data[key].created_at), "dd MMM yyyy HH:mm:ss");
                    data[key].from = parseInt(data[key].from);
                    data[key].to = parseInt(data[key].to);
                    array.push(data[key]);
                });
                setMessages(array);
                setMessageLoading(false);
                setChatsMsgLoading(false);
            });

            //New event to just add message

            // echo.channel(`allMessages.${current_booking.id}`)
            //     .subscribed(() => {
            //         // console.log("You are subscribed All Messages " + current_booking.id);
            //         // console.log("MEssages Loading", messageLoading)

            //         axios({
            //             method: "get",
            //             url: `${process.env.REACT_APP_API_URL}allMessages/${current_booking.id}`,
            //         });

            //         setMessageLoading(false);
            //         if (ignoreRefresh === false) {
            //             setChatsMsgLoading(true);
            //         }
            //     })
            //     .listen("GetAllMessages", (data) => {
            //         // console.log(data[2].id, userIdSelected, window.location.href.split("/").pop().split("=").pop())

            //         // console.log(data[2].id === parseFloat(window.location.href.split("/").pop().split("=").pop()));
            //         // console.log(window.location.href.split("/").pop().split("user_id=").pop().split("&")[0], data)

            //         if (data[2].id === parseFloat(window.location.href.split("/").pop().split("user_id=").pop().split("&")[0])) {
            //             const array = [];
            //             Object.keys(data[0]).forEach((key) => {
            //                 if (data[0][key].files !== null) {
            //                     data[0][key].files = data[0][key].files.split(",");
            //                 } else {
            //                     data[0][key].files = [];
            //                 }

            //                 data[0][key].timestamp = format(new Date(data[0][key].created_at), "dd MMM yyyy HH:mm:ss");
            //                 data[0][key].from = parseInt(data[0][key].from);
            //                 data[0][key].to = parseInt(data[0][key].to);
            //                 array.push(data[0][key]);
            //             });

            //             // console.log("Chat ID returned", data[1], current_booking.id)
            //             // console.log("Current | ", current_booking);

            //             setMessages(array);
            //             setChatsMsgLoading(false);

            //             // echo.disconnect();
            //         }
            //     });
        }

        let assignedFilter = "all";

        if (type === "mentions") {
            assignedFilter = "mine";
        }

        if (props.history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });

                    if (settings && settings.renewals === 1 && renewal_tag !== null) {
                        axios({
                            method: "get",
                            url: `${process.env.REACT_APP_API_URL}renewalsBookings_event/${renewal_tag}`,
                        });
                    }
                }
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}event/${type}`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tag_event/${tag_id}/${type}`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collection_event/${collection_tag_id}`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentions/${current_staff.id}`,
                });

                setAllowClick(false);
            }
        }

        // console.log("current_booking", current_booking)

        // console.log(user_id, current_booking, messageLoading);
        if (user_id > 0 && (current_booking === undefined || current_booking.user.id !== user_id) && messageLoading === false && ignoreRefresh === false) {
            setMessageLoading(true);
            // console.log("getBooking 1");
            // dispatch(getBooking(0, user_id));
            dispatch(getBooking(0, user_id, active_booking_id));

            // axios({
            //     method: "get",
            //     url: `${process.env.REACT_APP_API_URL}booking_user_id/${user_id}`,
            // }).then((response) => {
            //     const data = response.data;

            //     console.log("getBooking 1");
            //     dispatch(getBooking(data.id));
            // });
        }

        setIgnoreRefresh(false);

        return () => {
            echo.disconnect();
        };

        // echo.disconnect();

        // echo.connector.pusher.connection.bind('connected', (payload) => {
        //     console.log('connected')
        // });

        // echo.connector.pusher.connection.bind('disconnected', (payload) => {
        //     console.log('disconnected', payload);
        // });
    }, [current_booking, current_staff, type, tag_id, collection_tag_id, sortFilter, filterLocation, msgCount, showNewMessages, user_id, renewal_tag]);

    useEffect(() => {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;

        let echo;

        if (process.env.REACT_APP_REVERB_APP_ID) {
            echo = new Echo({
                broadcaster: "reverb", // Change "pusher" to "reverb"
                key: process.env.REACT_APP_REVERB_APP_KEY,
                wsHost: process.env.REACT_APP_REVERB_URL,
                wssHost: process.env.REACT_APP_REVERB_URL,
                wsPort: process.env.REACT_APP_REVERB_PORT,
                wssPort: process.env.REACT_APP_REVERB_PORT,
                disableStats: true,
                encrypted: process.env.REACT_APP_REVERB_SCHEME === "https",
                forceTLS: process.env.REACT_APP_REVERB_SCHEME === "https",
                enableLogging: true,
            });
        } else {
            echo = new Echo({
                broadcaster: "pusher",
                key: process.env.REACT_APP_MIX_ABLY_PUBLIC_KEY,

                wsHost: process.env.REACT_APP_MIX_ABLY_URL,
                wssHost: process.env.REACT_APP_MIX_ABLY_URL,

                wsPort: process.env.REACT_APP_MIX_ABLY_PORT,
                wssPort: process.env.REACT_APP_MIX_ABLY_PORT,

                disableStats: true,
                encrypted: false,
                enabledTransports: ["ws", "wss"],
                forceTLS: false,

                enableLogging: true,

                // unavailableTimeout: 1000,
                // activityTimeout: 1000,
                // pongTimeout: 2000,
            });
        }

        if (current_booking) {
            echo.channel(`incomingMessage.${current_booking.id}`)
                .subscribed(() => {
                    console.log("You are subscribes to incoming messages");
                })
                .listen("IncomingMessageEvent", (data) => {
                    if (data.files !== null) {
                        data.files = data.files.split(",");
                    } else {
                        data.files = [];
                    }

                    data.timestamp = format(new Date(data.created_at), "dd MMM yyyy HH:mm:ss");
                    data.from = parseInt(data.from);
                    data.to = parseInt(data.to);

                    setMessages((messages) => [...messages, data]);

                    //Ping on incoming message
                    function isMobileDevice() {
                        return /Mobi|Android/i.test(navigator.userAgent) || window.matchMedia("(max-width: 768px)").matches;
                    }

                    if (isMobileDevice()) {
                        const audio = new Audio("/message.mp3");
                        audio.play();
                    }
                });
        }

        return () => {
            echo.disconnect();
        };
    }, [current_booking]);

    var url_string = window.location.href;

    var url = new URL(url_string);
    var tag_id = url.searchParams.get("tag");
    var collection_tag_id = url.searchParams.get("collection_tag");
    var renewal_tag = url.searchParams.get("renewal_tag");

    var booking_list_name = type;

    if (tag_id) {
        var tag_data = tags.find((tag) => tag.id === parseInt(tag_id));
        booking_list_name = tag_data?.name;
    }

    if (collection_tag_id) {
        if (collection_tag_id == -1) {
            booking_list_name = "All Collections";
        } else {
            var collection_data = collections.find((collection) => collection.id === parseInt(collection_tag_id));
            booking_list_name = collection_data?.name;
        }
    }

    if (renewal_tag) {
        if (renewal_tag == -1) {
            booking_list_name = "All Renewals";
        } else {
            var renewals_data = renewals.find((renewal) => renewal.id === parseInt(renewal_tag));
            booking_list_name = renewals_data?.name;
        }
    }
    // else{
    //     var folder_data = folders.find((folder) => folder.href.split('/').pop() === type);
    //     booking_list_name = folder_data?.name;

    //     if(type === "mentions"){
    //         booking_list_name = "Mentions"
    //     }
    // }

    async function checkMailbox() {
        if (current_booking?.user?.t_p_n[(current_booking?.user?.t_p_n).length - 1].enquiryId) {
            setCheckingUpdates(true);
            await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}tpn/mailbox`,
                data: {
                    enquiryId: current_booking?.user?.t_p_n[(current_booking?.user?.t_p_n).length - 1].enquiryId,
                    tpnId: current_booking?.user?.t_p_n[(current_booking?.user?.t_p_n).length - 1].id,
                    user_id: current_staff.id,
                },
            })
                .then((response) => {
                    if (response.data[0] == "done") {
                        // console.log(response.data)
                        setActiveTPN(response.data[1]);
                    } else {
                        // toast(<NotificationToast title={"TPN Issue"} message={response.data} list={false} type="error" />);
                    }
                })
                .catch((response) => {
                    // toast(<NotificationToast title={"TPN Issue"} message={response.response.data} list={true} type="error" />);
                });
        }
        setCheckingUpdates(false);
    }

    function tpn_checks(type) {
        if (window.confirm("Running a TPN check will cost R 77.23. Are you sure that you want to continue?") == true) {
            setTpnLoading(true);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}tpn/consumer_enquiry/${current_booking.user.id}/${type}`,
            })
                .then((response) => {
                    if (response.data == "done") {
                        dispatch(getBooking(current_booking.id));
                    } else {
                        toast(<NotificationToast title={"TPN Issue"} message={response.data} list={false} type="error" />);
                    }
                })
                .catch((response) => {
                    toast(<NotificationToast title={"TPN Issue"} message={response.response.data} list={true} type="error" />);
                })
                .finally(() => {
                    setTpnLoading(false);
                });
        }
    }

    function mda_create_tenant(type, action) {
        if (type === "tenant" && action === "create") {
            setMdaLoadingT(true);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}mda/create_tenant/${current_booking.user.id}/${current_booking.id}`,
                data: { current_staff: current_staff },
            })
                .then((response) => {
                    let resp = response.data.split("|");

                    if (resp[0] === "updated" || resp[0] === "done") {
                        dispatch(getBooking(current_booking.id));

                        toast(<NotificationToast title={"Tenant Created"} message={"A tenant has been created on MDA"} />);
                    } else {
                        toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />, {
                            autoClose: false,
                        });
                    }
                })
                .catch((response) => {
                    toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />);
                })
                .finally(() => {
                    setMdaLoadingT(false);
                });
        } else if (type === "tenant" && action === "delete") {
            setMdaLoadingT(true);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}mda/delete_tenant/${current_booking.user.id}/${current_booking.id}`,
                data: { current_staff: current_staff },
            })
                .then((response) => {
                    if (response.data === "done") {
                        dispatch(getBooking(current_booking.id));

                        toast(<NotificationToast title={"Tenant Deleted"} message={"A tenant has been deleted on MDA"} />);
                    } else {
                        toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />, {
                            autoClose: false,
                        });
                    }
                })
                .catch((response) => {
                    toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />);
                })
                .finally(() => {
                    setMdaLoadingT(false);
                });
        } else if (type == "lease" && action == "create") {
            setMdaLoadingL(true);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}mda/create_lease/${current_booking.user.id}/${current_booking.id}`,
                data: { current_staff: current_staff },
            })
                .then((response) => {
                    if (response.data === "done" || response === "done") {
                        dispatch(getBooking(current_booking.id));

                        toast(<NotificationToast title={"Lease Created"} message={"A lease has been created on MDA"} />);
                    } else {
                        if (response.data === "Can't find Unit!") {
                            dispatch(openModal({ modal_type: "ModalMDAChooseUnit", modal_props: { current_booking: current_booking } }));
                        } else {
                            toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />, {
                                autoClose: false,
                            });
                        }
                    }
                })
                .catch((response) => {
                    toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />);
                })
                .finally(() => {
                    setMdaLoadingL(false);
                });
        } else if (type == "lease" && action == "delete") {
            setMdaLoadingL(true);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}mda/delete_lease/${current_booking.user.id}/${current_booking.id}`,
                data: { current_staff: current_staff },
            })
                .then((response) => {
                    if (response.data === "done") {
                        dispatch(getBooking(current_booking.id));

                        toast(<NotificationToast title={"Lease Deleted"} message={"A lease has been deleted on MDA"} />);
                    } else {
                        toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />, {
                            autoClose: false,
                        });
                    }
                })
                .catch((response) => {
                    toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />);
                })
                .finally(() => {
                    setMdaLoadingL(false);
                });
        } else if (type == "lease" && action == "unlink") {
            setMdaLoadingL(true);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}mda/unlink_lease/${current_booking.user.id}/${current_booking.id}`,
                data: { current_staff: current_staff },
            })
                .then((response) => {
                    // if (response == "done") {
                    //     dispatch(getBooking(current_booking.id));

                    //     toast(<NotificationToast title={"Lease Unlinked"} message={"A lease has been unlinked from MDA"} />);
                    // } else {
                    //     toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />, {
                    //         autoClose: false,
                    //     });
                    // }

                    dispatch(getBooking(current_booking.id));

                    toast(<NotificationToast title={"Lease Unlinked"} message={"A lease has been unlinked from MDA"} />);
                })
                .catch((response) => {
                    toast(<NotificationToast title={"MDA Issue"} message={response.data} type="error" />);
                })
                .finally(() => {
                    setMdaLoadingL(false);
                });
        }
    }

    async function switchWiFi(user_id, use_mac, id, count) {
        if (count === 1) {
            setWiFiLoading(true);
        } else {
            setWiFiLoading2(true);
        }

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}wifi-status-update`,
            data: { user_id: user_id, wifi_property_id: current_booking?.property?.wifi_property_id, current_staff: current_staff, use_mac: use_mac, id: id },
        })
            .then((response) => {
                setIgnoreRefresh(true);
                dispatch(getBooking(current_booking.id));
                toast(<NotificationToast title={"WIFI Status Updated"} message={"WiFi Updated"} />);
            })
            .catch((response) => {
                toast(<NotificationToast title={"WIFI Issue"} message={response.data} type="error" />);
            })
            .finally(() => {
                setWiFiLoading(false);
                setWiFiLoading2(false);
            });
    }

    async function unlinkWifiAccount() {
        setWiFiLoading(true);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}wifi-unlink-account`,
            data: {
                user_id: user_id,
                wifi_property_id: current_booking?.property?.wifi_property_id,
                wifi_package_id: current_booking?.property?.wifi_package_id,
                current_staff: current_staff,
                current_booking_id: current_booking.id,
            },
        })
            .then((response) => {
                setIgnoreRefresh(true);
                dispatch(getBooking(current_booking.id));
                toast(<NotificationToast title={"WIFI Account Unlinked"} message={"WiFi Unlinked"} />);
            })
            .catch((response) => {
                toast(<NotificationToast title={"WIFI Issue"} message={response.data} type="error" />);
            })
            .finally(() => {
                setWiFiLoading(false);
            });
    }

    async function linkWifiAccount() {
        setWiFiLoading(true);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}wifi-link-account`,
            data: {
                user_id: user_id,
                wifi_property_id: current_booking?.property?.wifi_property_id,
                wifi_package_id: current_booking?.property?.wifi_package_id,
                current_staff: current_staff,
                current_booking_id: current_booking.id,
            },
        })
            .then((response) => {
                setIgnoreRefresh(true);
                dispatch(getBooking(current_booking.id));
                toast(<NotificationToast title={"WIFI Account Linked"} message={"WiFi Linked"} />);
            })
            .catch((response) => {
                toast(<NotificationToast title={"WIFI Issue"} message={response.data} type="error" />);
            })
            .finally(() => {
                setWiFiLoading(false);
            });
    }

    async function createWifiAccount(user_id) {
        setWiFiLoading(true);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}wifi-create-account`,
            data: { user_id: user_id, wifi_property_id: current_booking?.property?.wifi_property_id, wifi_package_id: current_booking?.property?.wifi_package_id, current_staff: current_staff },
        })
            .then((response) => {
                setIgnoreRefresh(true);
                dispatch(getBooking(current_booking.id));
                toast(<NotificationToast title={"WIFI Account Created"} message={"WiFi Created"} />);
            })
            .catch((response) => {
                toast(<NotificationToast title={"WIFI Issue"} message={response.data} type="error" />);
            })
            .finally(() => {
                setWiFiLoading(false);
            });
    }

    async function checkWifi(property_id, account_id, username) {
        if (account_id !== null && username === null) {
            await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}wifi-check-details`,
                data: { user_id: user_id, wifi_property_id: property_id, wifi_account_id: account_id },
            })
                .then((response) => {
                    setIgnoreRefresh(true);
                    dispatch(getBooking(current_booking.id));
                    // toast(<NotificationToast title={"WIFI Account Created"} message={"WiFi Created"} />);
                })
                .catch((response) => {
                    // toast(<NotificationToast title={"WIFI Issue"} message={response.data} type="error" />);
                })
                .finally(() => {
                    // setWiFiLoading(false);
                });
        }
    }

    async function getBalance(booking_id, user_id) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}mda/get_balance`,
            data: { user_id: user_id, booking_id: booking_id },
        })
            .then((response) => {
                setMdaBalance(response.data);
            })
            .catch((response) => {
                toast(<NotificationToast title={"MDA Balance Issue"} message={"Balance could not be loaded"} type="error" />);
            });
    }

    // console.log(current_booking);
    if (loading === true) return <AppLoader />;

    return (
        <>
            {/* 3 column wrapper */}
            <div className="px-1 sm:px-4 my-2 xl:my-0 h-screen xl:flex w-full ">
                {/* Left sidebar & main wrapper */}
                {/* Start left column area */}
                <div className="rounded-3xl bg-light_background w-full xl:w-72 xl:relative flex-none">
                    <div className="min-h-14 flex rounded-t-3xl items-center justify-between px-4 bg-dark_background z-20 xl:w-72 relative xl:fixed">
                        <h3 className="text-lg leading-6 font-light text-white capitalize">
                            {booking_list_name}
                            {filterLocation.name !== "" && <div className="text-xs">{filterLocation.name}</div>}
                            {filterGender.name !== "" && <div className="text-xs">{filterGender.name}</div>}
                            {filterFunding.name !== "" && <div className="text-xs">{filterFunding.name}</div>}
                            {type == "collections" && filterVendor.name !== "" && <div className="text-xs">{filterVendor.name}</div>}
                            {type == "collections" && filterStatus.name !== "" && <div className="text-xs">{filterStatus.name}</div>}
                            {filterCount > 0 && <div className="text-xs">(Total: {filterCount})</div>}
                        </h3>

                        <div className="space-x-1">
                            <button
                                type="button"
                                className="text-white uppercase px-2 py-1 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 mt-1"
                                onClick={() => {
                                    dispatch(
                                        openModal({
                                            modal_type: "ModalFilterMessages",
                                            modal_props: {
                                                filterLocation,
                                                setFilterLocation,
                                                filterGender,
                                                setFilterGender,
                                                filterFunding,
                                                setFilterFunding,
                                                filterVendor,
                                                setFilterVendor,
                                                filterStatus,
                                                setFilterStatus,
                                                properties,
                                                genderOptions,
                                                fundingOptions,
                                                booking_list_name,
                                                filterDocuments,
                                                setFilterDocuments,
                                                type: type,
                                                tag_id: tag_id,
                                                collection_tag_id: collection_tag_id,
                                                current_staff_id: current_staff.id,
                                                renewal_tag: renewal_tag,
                                            },
                                        })
                                    );
                                }}
                            >
                                <FontAwesomeIcon icon={regular("filter")} className="h-5 w-5 text-white" />
                            </button>

                            {!current_staff.building_manager &&
                            ((current_staff.status === "Admin" && ["QP", "QL"].includes(process.env.REACT_APP_COLOUR)) || (process.env.REACT_APP_COLOUR === "UC" && tag_id != 30)) ? (
                                <button
                                    type="button"
                                    className="text-white uppercase px-2 py-1 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 mt-1"
                                    onClick={() =>
                                        dispatch(
                                            openModal({
                                                modal_type: "ModalBulkMessages",
                                                modal_props: {
                                                    allBookings,
                                                    bookings,
                                                    booking_list_name,
                                                    current_staff,
                                                    staff,
                                                    location_type: type,
                                                },
                                            })
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={regular("envelopes-bulk")} className="h-5 w-5 text-white" />
                                </button>
                            ) : (
                                ""
                            )}

                            {!current_staff.building_manager ? (
                                <button
                                    type="button"
                                    className="text-white uppercase px-2 py-1 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 mt-1"
                                    onClick={() =>
                                        dispatch(
                                            openModal({
                                                modal_type: "ModalBulkArchive",
                                                modal_props: {
                                                    allBookings,
                                                    bookings,
                                                    booking_list_name,
                                                    current_staff,
                                                    staff,
                                                    type,
                                                },
                                            })
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={regular("lock")} className="h-5 w-5 text-white" />
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    <BookingList
                        setMessageLoading={setMessageLoading}
                        bookings={bookings}
                        setFilterCount={setFilterCount}
                        setSortFilter={setSortFilter}
                        setMsgCount={setMsgCount}
                        tag_id={tag_id}
                        collection_tag_id={collection_tag_id}
                        renewal_tag={renewal_tag}
                        setShowNewMessages={setShowNewMessages}
                        filterLocation={filterLocation}
                        filterGender={filterGender}
                        filterFunding={filterFunding}
                        filterVendor={filterVendor}
                        filterStatus={filterStatus}
                        chatsLoading={chatsLoading}
                    />
                </div>
                {/* End left column area */}

                <div className="bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1">
                    {!current_booking && !messageLoading && <h1 className="p-4 h-full flex justify-center text-xl items-center">Select a booking to start a conversation</h1>}

                    {messageLoading && (
                        <h1 className="h-full flex justify-center text-2xl items-center">
                            <LoadingIcon />
                        </h1>
                    )}

                    {current_booking && !messageLoading && (
                        <ErrorBoundary>
                            <div className="py-3 pr-4 flex space-x-2 items-center relative rounded-t-3xl justify-between h-32 sm:h-14 bg-dark_background flex-wrap z-10 ">
                                <div className="px-4 sm:px-6 flex items-center space-x-2">
                                    <FontAwesomeIcon icon={regular("message")} className="h-5 w-5 text-primary" />
                                    <h3
                                        className={classNames(
                                            flagged_ids.length > 0 && flagged_ids.includes(current_booking?.user?.profile?.id_number)
                                                ? "text-red-400"
                                                : current_booking.name_override !== null
                                                ? "text-green-400"
                                                : current_booking?.year_id === settings.year_id
                                                ? "text-yellow-400"
                                                : "text-white",
                                            " text-lg font-light"
                                        )}
                                    >
                                        {current_booking?.booking_reference} -{" "}
                                        {current_booking.name_override === null ? `${current_booking?.user?.name || ""} ${current_booking?.user?.surname || ""}`.trim() : current_booking.name_override}
                                        {" - "}
                                        {/* {current_booking.name_override === null ? current_booking?.user?.name + " " + current_booking?.user?.surname : current_booking.name_override} -{" "} */}
                                        {current_booking?.property?.banking_reference} {current_booking?.unit?.name}
                                        {/* {current_booking?.bed?.name} */}
                                        {current_booking?.waiting_bed_id > 0 ? current_booking?.waiting_bed?.name : current_booking?.bed?.name}{" "}
                                        {current_booking?.waiting_bed_id > 0 ? " or similar. (on waitlist)" : ""}
                                        {process.env.REACT_APP_COLOUR === "UC" && (
                                            <>
                                                {current_booking?.student_type == 3 && "Private | "}
                                                {current_booking?.student_type == 2 && "Bursary | "}
                                                {(current_booking?.student_type == 1 || current_booking?.student_type == 4 || current_booking?.student_type == 6) && "NSFAS | "}
                                            </>
                                        )}
                                        {current_booking?.year?.year}
                                    </h3>
                                </div>

                                <BookingButtons setLoading={setLoading} current_booking={current_booking} historicBookings={historicBookings} />

                                {/* <div className="flex">
                                    {current_booking?.assigned_to?.length > 0 &&
                                        current_booking.assigned_to.map((a) => <ProfilePicture key={a} id={parseInt(a)} size="40" />)}
                                </div> */}
                            </div>
                            <div className="h-full xl:relative xl:-top-14">
                                {/* {console.log(chatsMsgLoading)} */}
                                {chatsMsgLoading === true ? (
                                    <div className="w-full h-full text-center">
                                        <span className="text-blue-comparemed top-1/2 my-0 mx-auto block relative w-72 h-72">
                                            <FontAwesomeIcon icon={regular("spinner-third")} className="text-black animate-spin text-5xl" />
                                        </span>
                                    </div>
                                ) : (
                                    <BookingMessages messages={messages} setMsgCount={setMsgCount} />
                                )}

                                <BookingForm type={type} tag_id={tag_id} />
                            </div>
                        </ErrorBoundary>
                    )}
                </div>

                <div className="my-6 xl:my-0 flex-none h-full overflow-y-scroll">
                    {/* Start right column area */}
                    {current_booking && !messageLoading && (
                        <>
                            <Disclosure>
                                {({ open }) => (
                                    <div className="xl:w-80">
                                        <Disclosure.Button
                                            className={`${
                                                open ? "rounded-t-3xl" : "rounded-3xl"
                                            } w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14 transition duration-100 lineaer`}
                                        >
                                            <FontAwesomeIcon icon={solid("user")} className="h-5 w-5 text-primary" />
                                            <h3 className="font-light text-sm text-white">Applicant Information</h3>
                                        </Disclosure.Button>
                                        <Transition
                                            enter="transition duration-100 ease-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-100 opacity-100"
                                            leave="transition duration-75 ease-out"
                                            leaveFrom="transform scale-100 opacity-100"
                                            leaveTo="transform scale-95 opacity-0"
                                        >
                                            <Disclosure.Panel className="rounded-b-3xl px-4 py-4 bg-gray-100 w-full ease-in duration-300">
                                                <GridSaveInformation current_staff={current_staff} setLoading={setLoading} setIgnoreRefresh={setIgnoreRefresh} />
                                            </Disclosure.Panel>
                                        </Transition>
                                    </div>
                                )}
                            </Disclosure>
                            <br />

                            {process.env.REACT_APP_COLOUR === "UC" && (
                                <>
                                    <Disclosure>
                                        {({ open }) => (
                                            <div className="xl:w-80">
                                                <Disclosure.Button
                                                    className={`${
                                                        open ? "rounded-t-3xl" : "rounded-3xl"
                                                    } w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14 transition duration-100 lineaer`}
                                                >
                                                    <FontAwesomeIcon icon={solid("user")} className="h-5 w-5 text-primary" />
                                                    <h3 className="font-light text-sm text-white">Queue Plate</h3>
                                                </Disclosure.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                >
                                                    <Disclosure.Panel className="rounded-b-3xl px-4 py-4 bg-gray-100 w-full ease-in duration-300">
                                                        <QueuePlate current_staff={current_staff} current_booking={current_booking} />
                                                    </Disclosure.Panel>
                                                </Transition>
                                            </div>
                                        )}
                                    </Disclosure>
                                    <br />
                                </>
                            )}

                            {type != "collections" ? (
                                <>
                                    {(!process.env.REACT_APP_HIDE_CHAT_TABS || process.env.REACT_APP_HIDE_CHAT_TABS === "false") && (
                                        <>
                                            <Disclosure defaultOpen>
                                                {({ open }) => (
                                                    <div className="xl:w-80">
                                                        <Disclosure.Button
                                                            className={`${open ? "rounded-t-3xl" : "rounded-3xl"} w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                        >
                                                            <FontAwesomeIcon icon={regular("users-rectangle")} className="h-5 w-5 text-primary" />
                                                            <h3 className="font-light text-sm text-white">Bookings</h3>
                                                        </Disclosure.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0"
                                                        >
                                                            <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                                <GridBookingInformation bookingGridCallback={handleHistoricBookingsCallback} />
                                                            </Disclosure.Panel>
                                                        </Transition>
                                                    </div>
                                                )}
                                            </Disclosure>
                                            <br />

                                            {!current_staff.building_manager ? (
                                                <>
                                                    <Disclosure>
                                                        {({ open }) => (
                                                            <div className="xl:w-80">
                                                                <Disclosure.Button
                                                                    className={`${
                                                                        open ? "rounded-t-3xl" : "rounded-3xl"
                                                                    } w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                                >
                                                                    <FontAwesomeIcon icon={solid("file-pen")} className="h-5 w-5 text-primary" />
                                                                    <h3 className="font-light text-sm text-white">Invoice and Lease</h3>
                                                                </Disclosure.Button>
                                                                <Transition
                                                                    enter="transition duration-100 ease-out"
                                                                    enterFrom="transform scale-95 opacity-0"
                                                                    enterTo="transform scale-100 opacity-100"
                                                                    leave="transition duration-75 ease-out"
                                                                    leaveFrom="transform scale-100 opacity-100"
                                                                    leaveTo="transform scale-95 opacity-0"
                                                                >
                                                                    <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                                        <GridInvoiceInformation setIgnoreRefresh={setIgnoreRefresh} />
                                                                    </Disclosure.Panel>
                                                                </Transition>
                                                            </div>
                                                        )}
                                                    </Disclosure>
                                                    <br />
                                                    <Disclosure>
                                                        {({ open }) => (
                                                            <div className="xl:w-80">
                                                                <Disclosure.Button
                                                                    className={`${
                                                                        open ? "rounded-t-3xl" : "rounded-3xl"
                                                                    } w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                                >
                                                                    <FontAwesomeIcon icon={solid("file-invoice")} className="h-5 w-5 text-primary" />
                                                                    <h3 className="font-light text-sm text-white">Additional Invoices</h3>
                                                                </Disclosure.Button>
                                                                <Transition
                                                                    enter="transition duration-100 ease-out"
                                                                    enterFrom="transform scale-95 opacity-0"
                                                                    enterTo="transform scale-100 opacity-100"
                                                                    leave="transition duration-75 ease-out"
                                                                    leaveFrom="transform scale-100 opacity-100"
                                                                    leaveTo="transform scale-95 opacity-0"
                                                                >
                                                                    <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                                        <GridAdditionalInvoice />
                                                                    </Disclosure.Panel>
                                                                </Transition>
                                                            </div>
                                                        )}
                                                    </Disclosure>
                                                    <br />
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Disclosure>
                                        {({ open }) => (
                                            <div className="xl:w-80">
                                                <Disclosure.Button
                                                    className={`${
                                                        open ? "rounded-t-3xl" : "rounded-3xl"
                                                    } w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14 transition duration-100 lineaer`}
                                                >
                                                    <FontAwesomeIcon icon={solid("gear")} className="h-5 w-5 text-primary" />
                                                    <h3 className="font-light text-sm text-white">Settings</h3>
                                                </Disclosure.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                >
                                                    <Disclosure.Panel className="rounded-b-3xl px-4 py-4 bg-gray-100 w-full ease-in duration-300">
                                                        <GridSettings />
                                                    </Disclosure.Panel>
                                                </Transition>
                                            </div>
                                        )}
                                    </Disclosure>
                                    <br />
                                    <Disclosure>
                                        {({ open }) => (
                                            <div className="xl:w-80">
                                                <Disclosure.Button
                                                    className={`${open ? "rounded-t-3xl" : "rounded-3xl"} w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                    onClick={() => checkWifi(current_booking?.property?.wifi_property_id, current_booking?.user?.wifi_account_id, current_booking?.user?.wifi_username)}
                                                >
                                                    <FontAwesomeIcon icon={regular("wifi")} className="h-5 w-5 text-primary" />
                                                    <h3 className="font-light text-sm text-white">WiFi</h3>
                                                </Disclosure.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                >
                                                    <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                        {current_booking?.property?.wifi_property_id !== null ? (
                                                            <>
                                                                {current_booking?.wi_fi.length > 0 ? (
                                                                    <>
                                                                        <CustomButton
                                                                            text="Unlink WIFI"
                                                                            styling="w-full mb-2"
                                                                            icon={regular("plus")}
                                                                            onClick={() => unlinkWifiAccount(current_booking?.user?.id)}
                                                                            loading={wiFiLoading}
                                                                        />
                                                                        <br />
                                                                        <hr />
                                                                        <br />
                                                                        <p>
                                                                            Wifi {current_booking?.wi_fi[0]?.mac_address === null ? "Account" : "Device"} ID:{" "}
                                                                            <span className="font-bold">{current_booking?.wi_fi[0]?.wifi_id}</span>
                                                                        </p>
                                                                        <br />

                                                                        {current_booking?.wi_fi[0]?.mac_address === null ? (
                                                                            <>
                                                                                <p>
                                                                                    Wifi Username: <span className="font-bold">{current_booking?.wi_fi[0]?.username}</span>
                                                                                </p>
                                                                                <p>
                                                                                    Wifi Password: <span className="font-bold">{current_booking?.wi_fi[0]?.password}</span>
                                                                                </p>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <p>
                                                                                    Wifi Name: <span className="font-bold">{current_booking?.wi_fi[0]?.name}</span>
                                                                                </p>
                                                                                <p>
                                                                                    Wifi Mac Address: <span className="font-bold">{current_booking?.wi_fi[0]?.mac_address}</span>
                                                                                </p>
                                                                            </>
                                                                        )}

                                                                        <br />
                                                                        <p>
                                                                            Wifi Status: <span className="font-bold">{current_booking?.wi_fi[0]?.status === 0 ? "OFF" : "ON"}</span>
                                                                        </p>
                                                                        <br />
                                                                        <CustomButton
                                                                            text={current_booking?.wi_fi[0]?.status === 0 ? "Switch WiFi On" : "Switch WiFi Off"}
                                                                            styling="w-full mb-2"
                                                                            icon={regular("chart-network")}
                                                                            onClick={() =>
                                                                                switchWiFi(current_booking?.user?.id, current_booking?.wi_fi[0]?.mac_address, current_booking?.wi_fi[0]?.id, 1)
                                                                            }
                                                                            loading={wiFiLoading}
                                                                        />

                                                                        {current_booking?.wi_fi.length === 2 ? (
                                                                            <>
                                                                                <br />
                                                                                <hr />
                                                                                <br />

                                                                                <p>
                                                                                    Wifi {current_booking?.wi_fi[1]?.mac_address === null ? "Account" : "Device"} ID:{" "}
                                                                                    <span className="font-bold">{current_booking?.wi_fi[1]?.wifi_id}</span>
                                                                                </p>
                                                                                <br />

                                                                                {current_booking?.wi_fi[1]?.mac_address === null ? (
                                                                                    <>
                                                                                        <p>
                                                                                            Wifi Username: <span className="font-bold">{current_booking?.wi_fi[1]?.username}</span>
                                                                                        </p>
                                                                                        <p>
                                                                                            Wifi Password: <span className="font-bold">{current_booking?.wi_fi[1]?.password}</span>
                                                                                        </p>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <p>
                                                                                            Wifi Name: <span className="font-bold">{current_booking?.wi_fi[1]?.name}</span>
                                                                                        </p>
                                                                                        <p>
                                                                                            Wifi Mac Address: <span className="font-bold">{current_booking?.wi_fi[1]?.mac_address}</span>
                                                                                        </p>
                                                                                    </>
                                                                                )}

                                                                                <br />
                                                                                <p>
                                                                                    Wifi Status: <span className="font-bold">{current_booking?.wi_fi[1]?.status === 0 ? "OFF" : "ON"}</span>
                                                                                </p>
                                                                                <br />
                                                                                <CustomButton
                                                                                    text={current_booking?.wi_fi[1]?.status === 0 ? "Switch WiFi On" : "Switch WiFi Off"}
                                                                                    styling="w-full mb-2"
                                                                                    icon={regular("chart-network")}
                                                                                    onClick={() =>
                                                                                        switchWiFi(current_booking?.user?.id, current_booking?.wi_fi[1]?.mac_address, current_booking?.wi_fi[1]?.id, 2)
                                                                                    }
                                                                                    loading={wiFiLoading2}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {/* {process.env.REACT_APP_COLOUR === "UC" ?
                                                                    <>
                                                                        <CustomButton text="Create Wifi Account" styling="w-full mb-2" icon={regular("plus")} onClick={() => createWifiAccount(current_booking?.user?.id)} loading={wiFiLoading} />
                                                                    </>
                                                                    :  */}
                                                                        {/*console.log(current_booking)*/}

                                                                        {current_booking?.bed?.wifi !== null && current_booking?.bed?.wifi?.booking_id != current_booking.id && (
                                                                            <CustomButton
                                                                                text="Link WIFI"
                                                                                styling="w-full mb-2"
                                                                                icon={regular("plus")}
                                                                                onClick={() => linkWifiAccount(current_booking?.user?.id)}
                                                                                loading={wiFiLoading}
                                                                            />
                                                                        )}

                                                                        {/* {current_booking?.bed?.wifi !== null &&
                                                                        current_booking?.bed?.wifi?.booking_id !== null &&
                                                                        current_booking?.bed?.wifi?.booking_id !== current_booking.id &&
                                                                        current_booking?.bed?.wifi.booking?.year_id != current_booking.year_id ? (
                                                                            <>WiFi Assigned to another years Booking</>
                                                                        ) : current_booking?.bed?.wifi !== null && current_booking?.bed?.wifi?.booking_id === null ? (
                                                                            <>
                                                                                <CustomButton
                                                                                    text="Link WIFI"
                                                                                    styling="w-full mb-2"
                                                                                    icon={regular("plus")}
                                                                                    onClick={() => linkWifiAccount(current_booking?.user?.id)}
                                                                                    loading={wiFiLoading}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            "No WiFi Account / Device"
                                                                        )} */}

                                                                        {/* } */}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <p>WiFi not available on {current_booking?.property?.name}</p>
                                                        )}
                                                    </Disclosure.Panel>
                                                </Transition>
                                            </div>
                                        )}
                                    </Disclosure>
                                    <br />
                                    <Disclosure>
                                        {({ open }) => (
                                            <div className="xl:w-80">
                                                <Disclosure.Button
                                                    className={`${open ? "rounded-t-3xl" : "rounded-3xl"} w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                >
                                                    <FontAwesomeIcon icon={regular("fingerprint")} className="h-5 w-5 text-primary" />
                                                    <h3 className="font-light text-sm text-white">Biometrics</h3>
                                                </Disclosure.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                >
                                                    <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                        {/* {process.env.REACT_APP_COLOUR === "UC" ? <GridBiometrics /> : ""} */}
                                                        {window.location.hostname === "localhost" ? <GridBiometrics /> : ""}
                                                    </Disclosure.Panel>
                                                </Transition>
                                            </div>
                                        )}
                                    </Disclosure>
                                    <br />

                                    <Disclosure>
                                        {({ open }) => (
                                            <div className="xl:w-80">
                                                <Disclosure.Button
                                                    className={`${open ? "rounded-t-3xl" : "rounded-3xl"} w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                >
                                                    <FontAwesomeIcon icon={regular("hand-holding-dollar")} className="h-5 w-5 text-primary" />
                                                    <h3 className="font-light text-sm text-white">POP's</h3>
                                                </Disclosure.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                >
                                                    <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                        <div className="h-60 overflow-y-scroll scroll-smooth">
                                                            {current_booking?.files && current_booking?.files.length > 0 ? (
                                                                <>
                                                                    {current_booking?.files.map((file) => (
                                                                        <>
                                                                            {file.file_type == "POP" ? (
                                                                                <>
                                                                                    <div
                                                                                        className="border-2 w-full rounded px-1 py-1 my-2 cursor-pointer hover:-translate-y-0.5 hover:bg-dark_background hover:text-white"
                                                                                        onClick={() =>
                                                                                            dispatch(
                                                                                                openModal({
                                                                                                    modal_type: "ModalViewFiles",
                                                                                                    modal_props: {
                                                                                                        files: [
                                                                                                            {
                                                                                                                id: file.id,
                                                                                                            },
                                                                                                        ],
                                                                                                    },
                                                                                                })
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <p className="text-center">{file.name}</p>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </Disclosure.Panel>
                                                </Transition>
                                            </div>
                                        )}
                                    </Disclosure>
                                    <br />
                                </>
                            )}

                            {(!process.env.REACT_APP_HIDE_CHAT_TABS || process.env.REACT_APP_HIDE_CHAT_TABS === "false") && (
                                <>
                                    {!current_staff.building_manager ? (
                                        <Disclosure onClick={() => checkMailbox()}>
                                            {({ open }) => (
                                                <div className="xl:w-80">
                                                    <Disclosure.Button
                                                        className={`${open ? "rounded-t-3xl" : "rounded-3xl"} w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                    >
                                                        <FontAwesomeIcon icon={solid("credit-card")} className="h-5 w-5 text-primary" />
                                                        <h3 className="font-light text-sm text-white"> {type != "collections" ? "Credit Check" : "BlackList / TPN"}</h3>
                                                    </Disclosure.Button>
                                                    <Transition
                                                        enter="transition duration-100 ease-out"
                                                        enterFrom="transform scale-95 opacity-0"
                                                        enterTo="transform scale-100 opacity-100"
                                                        leave="transition duration-75 ease-out"
                                                        leaveFrom="transform scale-100 opacity-100"
                                                        leaveTo="transform scale-95 opacity-0"
                                                    >
                                                        <Disclosure.Panel className="rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                            <div className="flex space-x-1 mb-1">
                                                                {!current_staff.building_manager ? (
                                                                    <>
                                                                        <CustomButton
                                                                            text="Consumer A"
                                                                            styling="w-full"
                                                                            icon={regular("user")}
                                                                            onClick={() => tpn_checks("consumer_a")}
                                                                            loading={tpnLoading ? 1 : 0}
                                                                            loadingtext="Please wait..."
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}

                                                                {current_booking.joint_application && !current_staff.building_manager ? (
                                                                    <CustomButton
                                                                        text="Consumer B"
                                                                        styling="w-full"
                                                                        icon={regular("user-group")}
                                                                        onClick={() => tpn_checks("consumer_b")}
                                                                        loading={tpnLoading ? 1 : 0}
                                                                        loadingtext="Please wait..."
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>

                                                            {current_booking?.user?.t_p_n.length > 0 && current_booking?.user?.t_p_n[(current_booking?.user?.t_p_n).length - 1].enquiryId ? (
                                                                <>
                                                                    <div className="border p-2">
                                                                        <p className="font-bold">Online Bank Statements:</p>
                                                                        {checkingUpdates === true ? (
                                                                            <>
                                                                                <FontAwesomeIcon icon={regular("spinner-third")} className="text-black animate-spin text-xs" /> Checking for Updates...
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <p>
                                                                                    {activeTPN.stmt_status}
                                                                                    <br />
                                                                                    {activeTPN.stmt_status_code}
                                                                                </p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}

                                                            {current_booking.company_application && !current_staff.building_manager ? (
                                                                <CustomButton
                                                                    text="Company"
                                                                    styling="w-full"
                                                                    icon={regular("buildings")}
                                                                    onClick={() => tpn_checks("company")}
                                                                    loading={tpnLoading ? 1 : 0}
                                                                    loadingtext="Please wait..."
                                                                />
                                                            ) : (
                                                                ""
                                                            )}

                                                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-5">
                                                                <table className="min-w-full divide-y divide-gray-300">
                                                                    <thead className="bg-gray-50">
                                                                        <tr>
                                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-light sm:pl-6" colSpan="4">
                                                                                Reports
                                                                            </th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody className="bg-white">
                                                                        {current_booking?.user?.t_p_n?.reverse().map((tpn, tpn_index) => {
                                                                            return (
                                                                                <tr key={"tpn_" + tpn.id} className={tpn_index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                        {tpn.report_type == 1 ? "Applicant 2" : tpn.report_type == 2 ? "Company" : "Applicant 1"}
                                                                                        <br />
                                                                                        {moment(tpn.created_at).format("DD/MM/YYYY H:mm:ss")}
                                                                                    </td>
                                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm font-medium  text-primary hover:text-primary_hover">
                                                                                        <a href={tpn.pdf_url} target="_blank">
                                                                                            PDF
                                                                                        </a>
                                                                                    </td>
                                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-primary hover:text-primary_hover">
                                                                                        <a href={tpn.report_url} target="_blank">
                                                                                            Report
                                                                                        </a>
                                                                                    </td>
                                                                                    <td>
                                                                                        <img src={tpn.credex_img} />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Disclosure.Panel>
                                                    </Transition>
                                                </div>
                                            )}
                                        </Disclosure>
                                    ) : (
                                        ""
                                    )}
                                </>
                            )}

                            {(!process.env.REACT_APP_HIDE_CHAT_TABS || process.env.REACT_APP_HIDE_CHAT_TABS === "false") && (
                                <>
                                    <br />
                                    <Disclosure>
                                        {({ open }) => (
                                            <div className="xl:w-80">
                                                <Disclosure.Button
                                                    className={`${open ? "rounded-t-3xl" : "rounded-3xl"} w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                    onClick={() => getBalance(current_booking.id, current_booking?.user.id)}
                                                >
                                                    <FontAwesomeIcon icon={solid("person-walking-dashed-line-arrow-right")} className="h-5 w-5 text-primary" />
                                                    <h3 className="font-light text-sm text-white">MDA</h3>
                                                </Disclosure.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                >
                                                    <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                        {current_booking?.user_mda === undefined ||
                                                        current_booking?.user_mda === null ||
                                                        current_booking?.user_mda?.mda_tenant_id === null ||
                                                        current_booking?.user_mda?.mda_tenant_id === 0 ? (
                                                            <>
                                                                {!current_staff.building_manager ? (
                                                                    <>
                                                                        <CustomButton
                                                                            text="Create Tenant"
                                                                            styling="w-full"
                                                                            icon={regular("link")}
                                                                            onClick={() => mda_create_tenant("tenant", "create")}
                                                                            loading={mdaLoadingT ? 1 : 0}
                                                                            loadingtext="Creating Tenant..."
                                                                        />
                                                                        <CustomButton
                                                                            text="Manually Link Tenant"
                                                                            styling="w-full mt-2"
                                                                            icon={regular("link")}
                                                                            onClick={() => setShowManualLink(true)}
                                                                            type="button"
                                                                        />
                                                                        {showManualLink === true ? (
                                                                            <>
                                                                                <Formik
                                                                                    initialValues={{ tenant_id: "" }}
                                                                                    validate={(values) => {
                                                                                        const errors = {};
                                                                                        if (!values.tenant_id) {
                                                                                            errors.tenant_id = "Required";
                                                                                        }
                                                                                        return errors;
                                                                                    }}
                                                                                    onSubmit={async (values, { setSubmitting }) => {
                                                                                        values.user_id = current_booking?.user.id;
                                                                                        values.booking_id = current_booking?.id;
                                                                                        await axios({
                                                                                            method: "post",
                                                                                            url: process.env.REACT_APP_API_URL + "mda/manual_mda_link",
                                                                                            data: values,
                                                                                        }).then((response) => {
                                                                                            dispatch(getBooking(current_booking.id));
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                                                                        <form onSubmit={handleSubmit} className="mt-2">
                                                                                            <input
                                                                                                type="text"
                                                                                                name="tenant_id"
                                                                                                onChange={handleChange}
                                                                                                onBlur={handleBlur}
                                                                                                value={values.tenant_id}
                                                                                                placeholder="Tenant Code"
                                                                                                className="rounded-lg block w-full font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                            />
                                                                                            {errors.tenant_id && touched.tenant_id && errors.tenant_id}
                                                                                            <br />
                                                                                            <button
                                                                                                type="submit"
                                                                                                disabled={isSubmitting}
                                                                                                className="bg-dark_background hover:bg-dark_background focus:ring-dark_background relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                                                                                            >
                                                                                                Save Tenant Code
                                                                                            </button>
                                                                                        </form>
                                                                                    )}
                                                                                </Formik>
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </>
                                                        ) : (
                                                            <div>
                                                                <ErrorBoundary>
                                                                    <div>
                                                                        <strong>Tenant ID:</strong> {current_booking?.user_mda?.mda_tenant_id}
                                                                    </div>

                                                                    <div>
                                                                        {mdaBalance ? (
                                                                            <>
                                                                                <strong>Balance {mdaBalance?.date} :</strong> R {mdaBalance?.balance !== null ? mdaBalance.balance.toFixed(2) : ""}
                                                                                <br />
                                                                                <strong>Current Balance: </strong> R {mdaBalance?.current !== null ? mdaBalance.current.toFixed(2) : ""}
                                                                                <br />
                                                                                <strong>Lease Start Date: </strong>{" "}
                                                                                {mdaBalance?.lease_start_date !== null && mdaBalance?.lease_start_date !== ""
                                                                                    ? format(new Date(mdaBalance.lease_start_date), "dd MMM yyyy")
                                                                                    : ""}
                                                                                <br />
                                                                                <strong>Lease End Date: </strong>{" "}
                                                                                {mdaBalance?.lease_end_date !== null && mdaBalance?.lease_end_date !== ""
                                                                                    ? format(new Date(mdaBalance.lease_end_date), "dd MMM yyyy")
                                                                                    : ""}
                                                                                <br />
                                                                                <strong>Current Rental: </strong> R{" "}
                                                                                {mdaBalance?.current_rental !== null && mdaBalance?.current_rental !== "" ? mdaBalance.current_rental.toFixed(2) : ""}
                                                                                <br />
                                                                                <strong>Parking: </strong> R {mdaBalance?.parking !== null ? mdaBalance.parking.toFixed(2) : ""}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>

                                                                    {!current_staff.building_manager && (process.env.REACT_APP_COLOUR !== "UC" || current_staff.isSuper == 1) ? (
                                                                        <CustomButton
                                                                            text={"Unlink Lease + Tenant from MDA"}
                                                                            styling="w-full mt-2"
                                                                            icon={regular("link-slash")}
                                                                            onClick={() => mda_create_tenant("lease", "unlink")}
                                                                            loading={mdaLoadingL ? 1 : 0}
                                                                            loadingtext="Deleting Lease..."
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}

                                                                    {current_booking?.rates &&
                                                                    current_booking?.rates.length > 0 &&
                                                                    current_booking?.rates[current_booking?.rates.length - 1].mda_lease_id &&
                                                                    current_booking?.rates[current_booking?.rates.length - 1].mda_lease_id !== null &&
                                                                    current_booking?.rates[current_booking?.rates.length - 1].mda_lease_id !== 0 ? (
                                                                        <>
                                                                            <div>Lease ID: {current_booking?.rates[current_booking?.rates.length - 1].mda_lease_id}</div>

                                                                            {!current_staff.building_manager && (process.env.REACT_APP_COLOUR !== "UC" || current_staff.isSuper == 1) ? (
                                                                                <CustomButton
                                                                                    text={"Delete Lease from MDA"}
                                                                                    styling="w-full mt-2"
                                                                                    icon={regular("link-slash")}
                                                                                    onClick={() => mda_create_tenant("lease", "delete")}
                                                                                    loading={mdaLoadingL ? 1 : 0}
                                                                                    loadingtext="Deleting Lease..."
                                                                                />
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {!current_staff.building_manager ? (
                                                                                <>
                                                                                    <CustomButton
                                                                                        text={"Delete Tenant (Permanent)"}
                                                                                        styling="w-full mt-2"
                                                                                        icon={regular("link-slash")}
                                                                                        onClick={() => mda_create_tenant("tenant", "delete")}
                                                                                        loading={mdaLoadingT ? 1 : 0}
                                                                                        loadingtext="Deleting Tenant..."
                                                                                    />

                                                                                    <CustomButton
                                                                                        text="Create Lease"
                                                                                        styling="w-full mt-2"
                                                                                        icon={regular("link")}
                                                                                        onClick={() => mda_create_tenant("lease", "create")}
                                                                                        loading={mdaLoadingL ? 1 : 0}
                                                                                        loadingtext="Creating Lease..."
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </ErrorBoundary>
                                                            </div>
                                                        )}
                                                    </Disclosure.Panel>
                                                </Transition>
                                            </div>
                                        )}
                                    </Disclosure>
                                    <br />
                                </>
                            )}

                            {(!process.env.REACT_APP_HIDE_CHAT_TABS || process.env.REACT_APP_HIDE_CHAT_TABS === "false") && (
                                <>
                                    {type != "collections" ? (
                                        <>
                                            {/* {window.location.hostname === "localhost" ? 
                                        <> */}
                                            <Disclosure>
                                                {({ open }) => (
                                                    <div className="xl:w-80">
                                                        <Disclosure.Button
                                                            className={`${open ? "rounded-t-3xl" : "rounded-3xl"} w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                        >
                                                            <FontAwesomeIcon icon={regular("calendar-check")} className="h-5 w-5 text-primary" />
                                                            <h3 className="font-light text-sm text-white">Appointments</h3>
                                                        </Disclosure.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0"
                                                        >
                                                            <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                                <GridBookingAppointments />
                                                            </Disclosure.Panel>
                                                        </Transition>
                                                    </div>
                                                )}
                                            </Disclosure>
                                            {/* </>
                                    : ""} */}

                                            <br />
                                            {!current_staff.building_manager ? (
                                                <Disclosure>
                                                    {({ open }) => (
                                                        <div className="xl:w-80">
                                                            <Disclosure.Button
                                                                className={`${
                                                                    open ? "rounded-t-3xl" : "rounded-3xl"
                                                                } w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                            >
                                                                <FontAwesomeIcon icon={regular("users-rectangle")} className="h-5 w-5 text-primary" />
                                                                <h3 className="font-light text-sm text-white">Payments</h3>
                                                            </Disclosure.Button>
                                                            <Transition
                                                                enter="transition duration-100 ease-out"
                                                                enterFrom="transform scale-95 opacity-0"
                                                                enterTo="transform scale-100 opacity-100"
                                                                leave="transition duration-75 ease-out"
                                                                leaveFrom="transform scale-100 opacity-100"
                                                                leaveTo="transform scale-95 opacity-0"
                                                            >
                                                                <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                                    <GridPayments />
                                                                </Disclosure.Panel>
                                                            </Transition>
                                                        </div>
                                                    )}
                                                </Disclosure>
                                            ) : (
                                                ""
                                            )}

                                            <br />
                                            <Disclosure>
                                                {({ open }) => (
                                                    <div className="xl:w-80">
                                                        <Disclosure.Button
                                                            className={`${open ? "rounded-t-3xl" : "rounded-3xl"} w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                        >
                                                            <FontAwesomeIcon icon={regular("house-person-return")} className="h-5 w-5 text-primary" />
                                                            <h3 className="font-light text-sm text-white">Move In / Out</h3>
                                                        </Disclosure.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0"
                                                        >
                                                            <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                                <GridMoveIn />
                                                            </Disclosure.Panel>
                                                        </Transition>
                                                    </div>
                                                )}
                                            </Disclosure>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </>
                            )}

                            {current_staff.id == "291" || current_staff.id == "1" ? (
                                <>
                                    <br />
                                    <Disclosure>
                                        {({ open }) => (
                                            <div className="xl:w-80">
                                                <Disclosure.Button
                                                    className={`${open ? "rounded-t-3xl" : "rounded-3xl"} w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                >
                                                    <FontAwesomeIcon icon={regular("list-check")} className="h-5 w-5 text-primary" />
                                                    <h3 className="font-light text-sm text-white">@'s</h3>
                                                </Disclosure.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                >
                                                    <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                        <AtInfo />
                                                    </Disclosure.Panel>
                                                </Transition>
                                            </div>
                                        )}
                                    </Disclosure>
                                </>
                            ) : (
                                ""
                            )}

                            {(!process.env.REACT_APP_HIDE_CHAT_TABS || process.env.REACT_APP_HIDE_CHAT_TABS === "false") && (
                                <>
                                    <Disclosure>
                                        {({ open }) => (
                                            <div className="xl:w-80 mt-5">
                                                <Disclosure.Button
                                                    className={`${open ? "rounded-t-3xl" : "rounded-3xl"} w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                >
                                                    <FontAwesomeIcon icon={regular("bag-shopping")} className="h-5 w-5 text-primary" />
                                                    <h3 className="font-light text-sm text-white">Orders</h3>
                                                </Disclosure.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                >
                                                    <Disclosure.Panel className="h-full rounded-b-3xl px-4 py-4 bg-gray-100 w-full">
                                                        <CustomOrders />
                                                    </Disclosure.Panel>
                                                </Transition>
                                            </div>
                                        )}
                                    </Disclosure>
                                </>
                            )}

                            {current_booking.location === "Residents" && type !== "collections" && current_staff.restrict_views === 0 ? (
                                <>
                                    <br />
                                    <Link to={"/booking/collections?collection_tag=-1&user_id=" + current_booking?.user.id + "&booking_id=" + current_booking.id}>
                                        <Disclosure>
                                            {({ open }) => (
                                                <div className="xl:w-80">
                                                    <Disclosure.Button
                                                        className={`${open ? "rounded-t-3xl" : "rounded-3xl"} w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                    >
                                                        <FontAwesomeIcon icon={regular("rectangle-history")} className="h-5 w-5 text-primary" />
                                                        <h3 className="font-light text-sm text-white">Collections</h3>
                                                    </Disclosure.Button>
                                                </div>
                                            )}
                                        </Disclosure>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    {current_booking.location === "Residents" && type === "collections" && current_staff.restrict_views === 0 ? (
                                        <>
                                            <br />
                                            <Link to={"/booking/management?tag=" + current_booking.tag + "&user_id=" + current_booking?.user.id + "&booking_id=" + current_booking.id}>
                                                <Disclosure>
                                                    {({ open }) => (
                                                        <div className="xl:w-80">
                                                            <Disclosure.Button
                                                                className={`${
                                                                    open ? "rounded-t-3xl" : "rounded-3xl"
                                                                } w-full py-3 pr-4 flex space-x-4 justify-center items-center bg-dark_background h-14`}
                                                            >
                                                                <FontAwesomeIcon icon={regular("rectangle-history")} className="h-5 w-5 text-primary" />
                                                                <h3 className="font-light text-sm text-white">Management</h3>
                                                            </Disclosure.Button>
                                                        </div>
                                                    )}
                                                </Disclosure>
                                            </Link>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
